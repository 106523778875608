import React from "react"

import Layout from "../components/layout"
import Categories from "../components/market-place/categories"
import Banner from "../components/market-place/banner"

export default function MarketPlace(props) {
  return (
    <Layout headerFixed={true} simple={true} page="Marketing" latestFooter={true}>
      <div className="h-full font-robo marketing-page">
        <Banner />
        <Categories />
      </div>
    </Layout>
  )
}
