import React from "react"

function Banner() {
  return (
    <div className="banner">
        <div className="banner__top">
          <div className="container">
            <div className="banner__top__inner flex flex-col">
              <h3 className="text-style">
              Business made easy with caring suppliers
              </h3>
              <h5 className="sub-text-style">
              Find and connect with trusted suppliers to make business payments and accounting transactions easier, simpler and smarter
              </h5>
            </div>
          </div>
        </div>
    </div>
  )
}
export default Banner;
