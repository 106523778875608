import React, { useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import vip from "../../images/market-place/vip.svg"
import mint from "../../images/market-place/mint.svg"
import volgai from "../../images/market-place/volgai_logo.jpg"
import volkslift from "../../images/market-place/volkslift.png"
import cornerston from "../../images/market-place/cornerston.svg"
import anconsult from "../../images/market-place/anconsult.png"
import cocalex from "../../images/market-place/cocalex.png"
import sweetNote from "../../images/market-place/sweet-note.svg"
import checkboxImg from "../../images/new-pricing/checked.svg"
import inpLogo from "../../images/market-place/inp-logo.png"
import collabartsLogo from "../../images/market-place/collabarts-logo.png"
import pisellLogo from "../../images/market-place/pisell-logo.png"
import ziiLogo from "../../images/market-place/zii_logo.png"
import jkkSolutionsLogo from "../../images/market-place/jkk_solutions_logo.png"
import sylaLogo from "../../images/market-place/syla-icon.png"
import kovaTaxSquareLogo from "../../images/market-place/kova-tax-square-logo.png"
import seederFinanceLogo from "../../images/market-place/seeder-finance.png"
import cfoLogo from "../../images/market-place/cfo-logo.png"
import targetAccoutingLogo from "../../images/market-place/target-accounting-logo.png"
import ozdLogo from "../../images/market-place/ozd_accounting_logo.jpg"
import financeMadeSimpleLogo from "../../images/market-place/finance_made_simple_logo.jpg"
import pinPointLocalLogo from "../../images/market-place/pinpoint-local_logo.png"
import usqLogo from "../../images/market-place/usq-logo.png"
import asaLogo from "../../images/market-place/asa-logo.png"

import Modali, { useModali } from "../modal"

function Items({ currentItems }) {
  return (
    <div className={`business sm:mt-20 mt-5 justify-center`}>
      {currentItems &&
      currentItems.map((item) => (
        <div>
          <h3>{item}</h3>
        </div>
      ))}
    </div>
  )
}
const market_places = [
  {
    header_img: vip,
    heading1: "Cleaning Business",
    heading2: "MY VIP Cleaning",
    link: "https://myvipcleaningservice.com.au/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "We provide professional cleaning services for homes, offices, Industries & Events, etc. We have a team of leading trained cleaning professionals in Melbourne who work with full care and devotion to providing a neat"
  },
  {
    header_img: volgai,
    // imgClassName: "w-145px flex items-center",
    heading1: "Software Development",
    heading2: "VolgAI",
    link: "https://volgai.com.au",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "VolgAI is a managed IT services company supporting organizations with complex requirements to find solutions that span their entire businesses. We are based in Melbourne but our services aren’t confined to one single country."
  },
  {
    header_img: volkslift,
    heading1: "Building & Construction",
    // imgClassName: "w-145px min-h-100px flex items-center",
    heading2: "Volkslift Australia",
    link: "https://volkslift.au/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "Volkslift,Germany, established since 1935, is based in Cologne, Germany. Since the production of it’ s first passenger elevator, Volkslift remained here as the pioneer and market leader in the elevator industry of Europe."
  },
  {
    header_img: sweetNote,
    heading1: "Design",
    heading2: "Sweet Notes Studio",
    link: "https://sweetnotesstudio.com/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "We provide professional cleaning services for homes, offices, Industries & Events, etc. We have a team of leading trained cleaning professionals in Melbourne who work with full care and devotion to providing a neat"
  },
  {
    header_img: inpLogo,
    heading1: "Accounting & Business Consulting",
    heading2: "INP Group",
    checkboxImg: checkboxImg,
    // imgClassName: "w-133px",
    link: "https://www.inpgroup.com.au",
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "INP Group is a well-established chartered accounting firm with extensive experience across various sectors and different sizes of clients including listed companies and large private international businesses."
  },
  {
    header_img: collabartsLogo,
    heading1: "Design",
    heading2: "Collabarts",
    checkboxImg: checkboxImg,
    link: "https://www.collabarts.com.au",
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "Never worry about content anymore. Talk us through your brand and social media strategy and we will create the content that strategically communicates your brand values, personalities, mission and goals."
  },
  {
    header_img: pisellLogo,
    // imgClassName: "w-145px min-h-105px flex items-center",
    heading1: "Software Services",
    heading2: "Pisel",
    link: "https://www.pisell.com/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "Pisell is a SaaS company dedicated to helping businesses find their customers, expand their online presence and improve efficiency in an all-round way. Founded in Australia, we are headquartered in Melbourne with offices all around Australia."
  },
  // {
  //   header_img: ziiLogo,
  //   imgClassName: "w-133px flex items-center min-h-105px",
  //   heading1: "POS",
  //   heading2: "Zii",
  //   link: "https://www.ziicloud.com/",
  //   checkboxImg: checkboxImg,
  //   checkText1: "LUCA Pay",
  //   checkText2: "E-invoicing enabled",
  //   body: "We are a team of technologists, business builders and entrepreneurs who have come together to transform high street commerce into a connected and thriving digital ecosystem that creates value for all participants."
  // } ,
  {
    header_img: cfoLogo,
    // imgClassName: "w-160px",
    heading1: "Accounting & Business Consulting",
    heading2: "Your CFO Partner",
    link: "https://yourcfopartner.com.au/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "With 15 years experience working with construction business, Victor set up the business back in 2017. Since then, he is on a mission to help every construction business he touch to maximize profit margin and optimize cash flow."
  },
  {
    header_img: targetAccoutingLogo,
    // imgClassName: "w-133px",
    heading1: "Accounting & Business Consulting",
    heading2: "Target Accounting",
    link: "https://targetaccounting.com.au/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "As a CPA and Xero certified advisor I’ve served at all levels of the business world in the last 10 years. There is nothing more fulfilling than empowering business owners to take control of their financial situation."
  },
  {
    header_img: ozdLogo,
    // imgClassName: "w-145px",
    heading1: "Accounting & Business Consulting",
    heading2: "OZD Accounting & Business Advisory",
    link: "http://www.ozdadvisory.com.au/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "We facilitate and make accounting and finance easy.  We believe in building and nurturing trusting Relationships. We do this by providing, Honest advice, Independence, Accountability, Leadership."
  },
  {
    header_img: anconsult,
    // imgClassName: "w-145px",
    heading1: "Accounting & Business Consulting",
    heading2: "Anconsult Business Solutions",
    link: "https://anconsult.com.au/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "In 2012, Anja Carruthers opened the first office of Anconsult Business Solutions on the Central Coast of New South Wales. Back then, we operated as a BAS agent specialising in small businesses."
  },
  {
    header_img: financeMadeSimpleLogo,
    imgWrapClassName: "financeLogo",
    heading1: "Financial Consulting, Business Lending & Digital Content Creation",
    heading2: "Finance Made Easy",
    link: "http://hofinancemadesimple.com.au/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "JBorrowing money for ventures such as buying your first home, purchasing an investment property or building a new home can be more complex than many people ..."
  },
  {
    header_img: pinPointLocalLogo,
    // imgClassName: "w-24 flex items-center",
    heading1: "Software Services",
    heading2: "Pinpoint Local",
    link: "https://pinpointlocalbusiness.com.au/google-my-business-management-services/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "Convert local Google searchers into customers with your Google Business Profile (GBP). With Pinpoint Local’s GBP Management Service, you will unlock the power of..."
  },
  {
    header_img: asaLogo,
    imgClassName: "asaLogo",
    heading1: "ASA Industry",
    // heading2: "Pinpoint Local",
    link: "www.asaindustry.com.au",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "ASA Industry, established in 1991, specializes in designing and manufacturing automation systems.If you are after running your three-phase motor from a single-phase power supply or just simply controlling your three phase motors, we are here to help. Our wide range of custom design products can ensure that we meet all your requirements."
  },
  {
    header_img: usqLogo,
    imgClassName: "usqLogo",
    heading1: "USQ Ledgerbrains",
    // heading2: "Pinpoint Local",
    link: "https://www.unitedsq.com/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "USQ is an accounting firm with a true leading edge in digital accounting technology and commitment to innovation & disciplined entrepreurship by MIT. They have a young and vibrant team of business consultants, chartered accountants, tax advisors and bookkeepers. They don’t just provide accounting and business services, they reinvent and streamline business processes by providing their customers with custom-developed, “in-house” apps and software that help their customers operate their businesses, ranging from payroll automation software to accounts payable automations, online financial dashboard reporting and customer portal mobile app."
  },
  {
    header_img: cocalex,
    // imgClassName: "w-12 min-h-105px flex items-center",
    heading1: "Financial Consulting, Business Lending & Digital Content Creation",
    heading2: "Cocalex",
    link: "https://www.cocalex.consulting/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "John has been a senior level executive within the lending and mortgage broking industry, having fulfilled a holistic repertoire of roles and projects in his 20 plus year career."
  },
  {
    header_img: jkkSolutionsLogo,
    // imgClassName: "w-145px flex items-center",
    heading1: "JKK Solutions",
    heading2: "Building & Construction",
    link: "https://jkksolutions.com.au/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "JKK Solutions: Your Trusted Financial Partner JKK Solutions is an award-winning Mortgage Broking and Financial Services organization. We specialize in complex cashflow lending, property transactions, and investor portfolio lending. Our passion is helping our customers grow and succeed. Trust, respect, and creating value for each other are at the core of our values. Choose JKK Solutions for comprehensive finance solutions tailored to your needs. Contact us today to unlock your financial potential."
  },
  {
    header_img: sylaLogo,
    // imgClassName: "w-145px flex items-center",
    heading1: "Syla",
    heading2: "Accounting & Business Consulting",
    link: "https://www.syla.com.au/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "Meet Syla - Australia’s crypto tax software, created by a team of Australian tax professionals, exclusively for Australian crypto investors. Whether you’re an individual or SMSF, you can easily generate ATO-compliant tax reports that are optimised to save you tax."
  },
  {
    header_img: kovaTaxSquareLogo,
    // imgClassName: "w-145px flex items-center",
    heading1: "Kova Tax",
    heading2: "Accounting & Business Consulting",
    link: "https://kovatax.com.au/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "Kova Tax is a tech-driven accounting firm building a better digital world for businesses, SMSFs and investors in the cryptocurrency sector.We integrate technology and software at the core of our services so that we can work with you across all areas of cryptocurrency investment. Helping you to make more informed decisions, improve your tax-strategy and help you achieve the best outcome."
  },
  {
    header_img: seederFinanceLogo,
    // imgClassName: "w-145px flex items-center",
    heading1: "Seeder Finance",
    heading2: "Finance & Business Consulting",
    link: "https://www.seederfinance.com.au/",
    checkboxImg: checkboxImg,
    checkText1: "LUCA Pay",
    checkText2: "E-invoicing enabled",
    body: "Are you self-employed and need help navigating the finance world? Seeder Finance is built off the back of nearly 15 years of business banking experience with one of Australia’s major banks. Our hallmark are the strong relationships we build with our clients to help them find the right solutions for them and their business"
  }
]

export default function Categories() {
  const itemsPerPage = 9
  // We start with an empty list of items.
  const [currentItems, setCurrentItems] = useState(null);
  const [pageCount, setPageCount] = useState(0);
  // Here we use item offsets; we could also use page offsets
  // following the API or data you're working with.
  const [itemOffset, setItemOffset] = useState(0);
  const [contactModal, toggleContactModal] = useModali()
  const [placeObject, updatePlaceObject] = useState({})

  let items = [];
   market_places.map((place, index) => (
        items.push(<div className="justify-content-center sm:grid box relative gap-3 flex-col" >
              <div className={`header flex justify-content-center align-items-center cursor-pointer`}>
                <img src={place.header_img} className={`img1 ${place.imgClassName}`} onClick={() => window.open(place.link)} />
              </div>
              {/*<hr className="hr" />*/}
              <div className="content text-neutral-3 text-15">
                <div className="color-grey"> {place.heading1} </div>
                <div className="font-weight-5"> {place.heading2} </div>
                {/*<div className="flex text-item">*/}
                  {/*<img src={place.checkboxImg} className="pr-5" /> <span className="pr-2"> {place.checkText1} </span>*/}
                  {/*<img src={place.checkboxImg} className="pr-5" /><span className="pr-2"> {place.checkText2} </span>*/}
                {/*</div>*/}
                <div className="pt-3 flex flex-col">
                  <div className={`text font-normal`}>
                    {place.body.length > 220 ? `${place.body.slice(0, 220)} ...` : place.body}
                    {/*<span className='pl-1'><a className={`text-mind read-more cursor-pointer hover:underline`} onClick={() => readmore(place)}>*/}
                    <span className='pl-1'>
                      <a className={`text-mind read-more cursor-pointer hover:underline`} onClick={() => window.open(place.link)}>
                       read more
                      </a>
                    </span>
                  </div>

                </div>
          </div>
    </div>)
   ))


  useEffect(() => {
    // Fetch items from another resources.
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  }, [itemOffset, itemsPerPage]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % items.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
  };

  const readmore = (place) => {
      updatePlaceObject(place)
      toggleContactModal()
  }

  return (
    <div className="container">
      <div className="categories">
        <div className="business-wrap paginate">
          <div className="flex sm:flex-row flex-col sm:pt-10 pt-0">
            <div className="category-without-border">Categories</div>
            <div className={`flex  flex-wrap justify-start sm:mt-0 mt-2 gap-y-3`}>
              <div className="category"> Hospitality </div>
              <div className="category"> Retail </div>
              <div className="category"> Construction </div>
              <div className="category">Service  </div>
              <div className="category"> Supermarket </div>
              <div className="category">  Pharmacy (Medical) </div>
              <div className="category">  Finance and Accounting </div>
              <div className="category">  Wholesale </div>
              <div className="category">  Optometrist (Medical) </div>
              <div className="category"> Sales and Marketing </div>
              <div className="category-without-border color-grey"> See all </div>
            </div>
          </div>
          <Items currentItems={currentItems} />
          <ReactPaginate
            breakLabel="..."
            nextLabel=" >"
            onPageChange={handlePageClick}
            marginPagesDisplayed={0}
            pageRangeDisplayed={2}
            pageCount={pageCount}
            previousLabel="< "
            renderOnZeroPageCount={null}
          />
          <Modali.Modal {...contactModal} hubspot={true} showLogo={false} className={` business-modal`}>
            {placeObject ?
                      <div className="box relative ">

                        <div className="text-neutral-3 text-15">
                          <div className={`px-10 pb-6 modal-header border-custome `}>
                            <div className="color-grey-modal text-xs text-initial absolute -mt-6"> {placeObject.heading1} </div>
                            <div className="font-weight-5-modal text-initial"> {placeObject.heading2} </div>
                            <div className='flex items-center pt-1'>
                              <div className='flex items-center'>
                                <img src={placeObject.checkboxImg} className="pr-2" /> <span className="pr-2"> {placeObject.checkText1} </span>
                              </div>
                              <div className='flex items-center'>
                                 <img src={placeObject.checkboxImg} className="pr-2" /><span className="pr-2"> {placeObject.checkText2} </span>
                              </div>
                            </div>
                          </div>
                          <div className="text-initial text-modal px-10 py-6">
                            {placeObject.body}
                          </div>

                        </div>
                      </div>
              : ''}
          </Modali.Modal>
        </div>
      </div>
    </div>
  )
}
